import {useAppToast} from '~/stores/appToast';
import type {Assignment, AssignmentOffer} from '~/types';
import type {AssignmentEngagementList} from '~/types/assignment-engagement-list.interface';

export const useAssignmentStore = defineStore('assignmentStore', () => {
  const {showApiErrorToast} = useAppToast();

  const currentAssignment = ref<Assignment>();
  const currentAssignmentEngagementList = ref<AssignmentEngagementList>();
  const currentAssignmentEngagementListItemsCount = computed(
    () => currentAssignmentEngagementList.value?.items.length || 0,
  );

  function fetchAssignment(assignmentId: string) {
    return useGetJobApi(`/assignments/{assignmentId}`, {
      method: 'GET',
      path: {
        assignmentId,
      },
      onResponseError: showApiErrorToast,
    });
  }

  function leaveOffer(assignmentId: string, offer: AssignmentOffer) {
    return useGetJobApi(`/assignments/{assignmentId}/engagements/my-offer`, {
      method: 'PUT',
      body: offer,
      path: {
        assignmentId,
      },
      onResponseError: showApiErrorToast,
    });
  }

  function fetchAssignmentEngagements(count = 10, offset = 0) {
    return useGetJobApi(`/assignments/{assignmentId}/engagements`, {
      method: 'GET',
      path: {
        assignmentId: currentAssignment.value!.id!,
      },
      params: {
        count,
        offset,
      },
      onResponseError: showApiErrorToast,
    });
  }

  function hideAssignment() {
    return useGetJobApi(`/assignments/{assignmentId}/hide`, {
      method: 'POST',
      path: {
        assignmentId: currentAssignment.value!.id!,
      },
      onResponseError: showApiErrorToast,
    });
  }

  function publishAssignment() {
    return useGetJobApi(`/assignments/{assignmentId}/publish-again`, {
      method: 'POST',
      path: {
        assignmentId: currentAssignment.value!.id!,
      },
      onResponseError: showApiErrorToast,
    });
  }

  function acceptServiceProvider(serviceProviderId: string) {
    return useGetJobApi(
      `/assignments/{assignmentId}/engagements/{serviceProviderId}/accept`,
      {
        method: 'POST',
        path: {
          assignmentId: currentAssignment.value!.id!,
          serviceProviderId,
        },
        onResponseError: showApiErrorToast,
      },
    );
  }

  function completeAcceptedEngagements() {
    return useGetJobApi(
      `/assignments/{assignmentId}/complete-accepted-engagement`,
      {
        method: 'POST',
        path: {
          assignmentId: currentAssignment.value!.id!,
        },
        onResponseError: showApiErrorToast,
      },
    );
  }

  function setCurrentAssignment(assignment: Assignment) {
    currentAssignment.value = assignment;
  }

  function setCurrentAssignmentEngagements(
    assignmentEngagements: AssignmentEngagementList,
  ) {
    currentAssignmentEngagementList.value = assignmentEngagements;
  }

  function addCurrentAssignmentEngagements(
    assignmentEngagements: AssignmentEngagementList,
  ) {
    const {count, offset, items} = assignmentEngagements;

    currentAssignmentEngagementList.value = {
      offset,
      count,
      items: [
        ...(currentAssignmentEngagementList.value?.items || []),
        ...items,
      ],
    };
  }

  function $reset() {
    currentAssignment.value = undefined;
    currentAssignmentEngagementList.value = undefined;
  }

  return {
    currentAssignment,
    currentAssignmentEngagementList,
    currentAssignmentEngagementListItemsCount,
    setCurrentAssignment,
    setCurrentAssignmentEngagements,
    addCurrentAssignmentEngagements,
    fetchAssignment,
    fetchAssignmentEngagements,
    leaveOffer,
    hideAssignment,
    publishAssignment,
    acceptServiceProvider,
    completeAcceptedEngagements,
    $reset,
  };
});
